import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, Alert, Spinner } from 'react-bootstrap';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import { useUserContext } from 'contexts/Users';
import { getJobTypes } from 'api/jobTypes';
import CropSKUReference from './CropSKUReference';

function CropProducts({ onChange, selectedLabel, prevSelectedUuid, setLabelName, onSelect }) {
  const {
    isLoadingSKU,
    warning,
    handleJobTypeChange,
    loadPredefinedClass,
    predefinedSet,
    clearSKU,
    selectedJobType
  } = useBatchLabelingContext();

  const { isLabeler, assignJobTypes } = useUserContext();

  const [labelValue, setLabelValue] = useState('');
  const [showMoreCharactersInfo, setShowMoreCharactersInfo] = useState(false);

  const style = {
    background: '#fff',
    borderTop: '1px solid #E3E3E3',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #E3E3E3'
  };

  useEffect(() => {
    if (!selectedJobType) {
      handleJobTypeChange(null);
    }
    onChange('');
  }, [selectedJobType]);

  useEffect(() => {
    onChange(labelValue);
    if (!labelValue) {
      clearSKU();
      return;
    }
    if (labelValue.length < 3) {
      setShowMoreCharactersInfo(true);
      clearSKU();
      return;
    }
    setShowMoreCharactersInfo(false);
    // search when user stops typing
    const delayDebounceFn = setTimeout(() => {
      loadPredefinedClass(labelValue);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [labelValue]);

  useEffect(() => {
    if (isLabeler) {
      const fetchJobTypes = async () => {
        try {
          const response = await getJobTypes();
          const data = await response.json();
          const jobTypeList = data.results;
          if (assignJobTypes.length > 0) {
            const assignedJobTypes = jobTypeList.filter(
              (jobType) => jobType.id === assignJobTypes[0]
            );
            if (assignedJobTypes.length > 0) {
              // clear selected label
              onChange('');
              handleJobTypeChange(assignedJobTypes[0]);
            } else {
              console.error('Assigned job type not found.');
            }
          }
        } catch (error) {
          console.error('Error fetching job types:', error);
        }
      };
      fetchJobTypes();
    }
  }, [isLabeler, assignJobTypes]);

  useEffect(() => {
    if (prevSelectedUuid) {
      loadPredefinedClass(prevSelectedUuid, 1, 'entity_id');
    }
  }, [prevSelectedUuid]);

  useEffect(() => {
    if (!predefinedSet.length || !prevSelectedUuid || selectedLabel) return;
    // set previously selected label name
    onChange(predefinedSet[0].name);
    setLabelName(predefinedSet[0].name);
  }, [predefinedSet, prevSelectedUuid]);

  return (
    <div>
      {selectedJobType && (
        <>
          {warning && <Alert variant="warning">{warning}</Alert>}
          {showMoreCharactersInfo && (
            <Alert variant="info">Please type at least 3 characters</Alert>
          )}
          <div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <InputGroup className="mt-3 mb-3 input-group--border">
                <FormControl
                  aria-describedby="basic-addon2"
                  autoFocus
                  list="searchDropdownInputId"
                  value={selectedLabel}
                  placeholder="Search for new label"
                  onChange={(e) => {
                    setLabelValue(e.target.value);
                  }}
                />
                {selectedLabel && (
                  <button
                    style={style}
                    onClick={() => {
                      onChange('');
                      clearSKU();
                    }}
                    type="button"
                    className="inner-btn btn-primary-outline"
                  >
                    <i style={{ color: '#565656' }} className="fa fa-times" />
                  </button>
                )}
              </InputGroup>
            </div>
            {isLoadingSKU && <Spinner animation="border" />}
            <CropSKUReference
              newLabel={selectedLabel}
              onClick={(el) => {
                if (onSelect) onSelect(el);
                onChange(el.name);
              }}
              selectedLabel={selectedLabel}
            />
          </div>
        </>
      )}
    </div>
  );
}

CropProducts.propTypes = {
  onChange: PropTypes.func,
  selectedLabel: PropTypes.string,
  onSelect: PropTypes.func
};

CropProducts.defaultProps = {
  onChange: () => {},
  selectedLabel: '',
  onSelect: undefined
};

export default CropProducts;
