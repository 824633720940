import React, { useState, useEffect } from 'react';
import { Card, Button, Accordion, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { moveToNewCluster } from 'api/clusters';
import { CropListPreviewButton } from './CropListPreviewButton';
import { reorderPoints } from '../../api/crops';

function ButtonSpinner() {
  return <Spinner size="md" animation="border" role="status" />;
}

function CropsElement({ crops, elUniq, setSelectedCrop, selectedCrops, onPreviewClicked }) {
  const cropList = elUniq === 0
      ? crops.filter((el) => el.clusterID === 0).sort((a,b) => a.sortNumber - b.sortNumber)
      : crops.filter((el) => el.clusterID === elUniq);

  return (
    <>
      {cropList
        .map((crop) => (
          <span
            className="crop-list-container"
            key={crop.id}
            onClick={() => setSelectedCrop(crop.id)}
            style={{
              border:
                selectedCrops && selectedCrops.length && selectedCrops.includes(crop.id)
                  ? '3px solid red'
                  : '3px solid transparent'
            }}
          >
            <div>
              <img src={crop.crop_path} alt="crop" />
            </div>
            <CropListPreviewButton onClick={onPreviewClicked} crop={crop} />
          </span>
        ))}
    </>
  );
}

function CropDraftLists({
  data,
  onPreviewClicked,
  setSelectedCrop,
  setSelectedCrops,
  selectedCrops,
  userType,
  refreshData,
  stopLoading,
  stopLoadingFunction,
  setData,
  reCountClusterId,
  setErrorMessage
}) {
  const { id } = data;
  const crops = data.points || [];
  const isRejected = data.reject_flag;
  const [isBusy, setIsBusy] = useState(false);
  const [cropsListUniq, setCropsListUniq] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSortError, setIsSortError] = useState(false);

  useEffect(() => {
    const cropsList = [0, ...crops.map((el) => el.clusterID)];
    const makeUniq = (arr) => arr.filter((el, id) => arr.indexOf(el) === id);
    const cropsListUniq = makeUniq(cropsList).sort((a,b) => a-b );
    setCropsListUniq(cropsListUniq);
  }, [data]);

  useEffect(() => {
    setIsBusy(stopLoading);
  }, [stopLoading]);

  useEffect(() => {
    const selectedMainCluster =  crops.filter((el) => selectedCrops.includes(el.id) && el.clusterID === 0);
    const newIsSelected = selectedMainCluster.length > 0;
    setIsSelected(newIsSelected);
  }, [selectedCrops]);

  const deleteDraftCluster = (draftClusterID) => {
    const newSelected = [];
    const newCrops = crops.map((el) => {
      if (el.clusterID === draftClusterID) {
        return { ...el, clusterID: 0 };
      }
      if (selectedCrops.includes(el.id)) {
        newSelected.push(el.id);
      }
      return el;
    });
    setSelectedCrops(newSelected);
    const { newCropsReCount, newClusterCount } = reCountClusterId(newCrops);
    const newData = {
      ...data,
      points: [...newCropsReCount],
      clusterCount: newClusterCount
    };
    setData(newData);
  };

  const clearSelectedCluster = (clusterID) => {
    const unSelected = crops.filter((el) => el.clusterID === clusterID).map((el) => el.id);
    const newSelected = selectedCrops.filter((el) => !unSelected.includes(el));
    setSelectedCrops(newSelected);
  };

  const deselectAll = () => {
    const newSelection = [];
    for (let i = 0; i < crops.length; i += 1) {
      const crop = crops[i];
      if (crop.clusterID !== 0 && selectedCrops.includes(crop.id)) {
        newSelection.push(crop.id);
      }
    }
    setSelectedCrops(newSelection);
  };

  const revertSelection = () => {
    const newSelection = [];
    for (let i = 0; i < crops.length; i += 1) {
      const crop = crops[i];
      if (crop.clusterID !== 0 && selectedCrops.includes(crop.id)) {
        newSelection.push(crop.id);
      }
      if (crop.clusterID === 0 && !selectedCrops.includes(crop.id)) {
        newSelection.push(crop.id);
      }
    }
    setSelectedCrops(newSelection);
  };

  const selectAllBelow = () => {
    const selection = [...selectedCrops];
    const cropsMainCluster = crops.filter((el) => el.clusterID === 0);
    if (selection.length === 0) {
      // No selected crops, nothing to do
      return;
    }

    // Find the highest index among selected crops in all crops list
    const highestIndex = Math.max(
      ...selection.map((selectedCrop) =>
        cropsMainCluster.find((cropObject) => cropObject.id === selectedCrop).sortNumber
      )
    );

    // Fill selectedCrops with crops id that have higher index in all crops list
    cropsMainCluster.forEach((crop) => {
      if (crop.sortNumber > highestIndex && !selection.includes(crop.id)) {
        selection.push(crop.id);
      }
    })

    setSelectedCrops(selection);
  };

  const checkResponse = async (response) => {
    if (response.status === 200) {
      refreshData();
    } else {
      const data = await response.json();
      setErrorMessage(data.detail || 'Error');
    }
  };

  const handleMoveToNewCluster = async (draftClusterID) => {
    setIsBusy(true);
    const params = userType === 'labeler' ? null : { fix: true };
    const movedCrops = [];
    crops.forEach((el) => (el.clusterID === draftClusterID ? movedCrops.push(el.id) : null));
    await checkResponse(await moveToNewCluster(id, params, { crop_ids: movedCrops }));
  };

  const sortBySelected = async () => {
    setIsBusy(true);
    const cropForSort = [];
    const cropForNegativeSort = [];
    crops.forEach((el) => {
      if(el.clusterID === 0) {
        if (selectedCrops.includes(el.id)) {
          cropForSort.push(el.id)
        }
      }
      else {cropForNegativeSort.push(el.id)}
    })
    const props = {
      "points": cropForSort,
      "negative_points": cropForNegativeSort
    }
    const response = await reorderPoints(id, props);

    if(response.status === 200) {
      await response.json()
          .then((data) =>{
            const cropSorter = data.point_ids;
            const selectedCropsLength = cropForSort.length;
            const sortedCropsLength = cropSorter.length;
            crops.forEach((crop) => {
              if(cropForSort.includes(crop.id)) {
                crop.sortNumber = cropForSort.indexOf(crop.id);
              } else if(cropSorter.includes(crop.id)) {
                crop.sortNumber = selectedCropsLength + cropSorter.indexOf(crop.id);
              } else if(cropForNegativeSort.includes(crop.id)) {
                crop.sortNumber = selectedCropsLength + sortedCropsLength + cropForNegativeSort.indexOf(crop.id);
              }
            })

          })
          .catch(() => setIsSortError(true))
    }
    else {
      setIsSortError(true)
    }
     setIsBusy(false);
  }

  return (
    <div className={isRejected ? 'accordionParent accordionError' : 'accordionParent'}>
      {crops.length && !isBusy && (
        <>
          {cropsListUniq.map((elUniq) => (
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    eventKey={`${elUniq}`}
                    onClick={() => clearSelectedCluster(elUniq)}
                  >
                    {elUniq === 0 ? <>main</> : <>draft cluster # {elUniq}</>}
                    <FontAwesomeIcon icon={faPlus} />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`${elUniq}`}>
                  <Card.Body>
                    <FontAwesomeIcon icon={faMinus} className="topMinus" />
                    {crops.length > 0 && elUniq === 0 && (
                      <div className="topButton">
                        <Button className="ml-2 mb-2" variant="defoult" onClick={() => deselectAll()}>
                          Deselect All
                        </Button>
                        <Button
                          className="ml-2 mb-2"
                          variant="defoult"
                          onClick={() => revertSelection()}
                        >
                          Invert Selection
                        </Button>
                        <Button className="ml-2 mb-2" variant="defoult" onClick={() => selectAllBelow()}>
                          Select All Below
                        </Button>
                        <Button className="ml-2 mb-2" variant="defoult" onClick={() => sortBySelected()} disabled={!isSelected}>
                          Sort by selected
                        </Button>
                        {isSortError && (
                            <div className="error-text ml-2 mr-2 mb-2">Sorry, something went wrong</div>
                        )}
                      </div>
                    )}
                    <div className="mainContainer">
                      <CropsElement
                        crops={crops}
                        elUniq={elUniq}
                        setSelectedCrop={setSelectedCrop}
                        selectedCrops={selectedCrops}
                        onPreviewClicked={onPreviewClicked}
                      />
                    </div>
                    {elUniq > 0 && (
                      <div className="btnButton">
                        <Button
                          className="ml-2"
                          variant="danger"
                          title="delete cluster - move all crops to main cluster"
                          onClick={() => deleteDraftCluster(elUniq)}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        {userType === 'labeler' && (
                          <Button
                            className="ml-2"
                            variant="defoult"
                            onClick={() => handleMoveToNewCluster(elUniq)}
                          >
                            create new cluster
                          </Button>
                        )}
                      </div>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
        </>
      )}
      {isBusy && <ButtonSpinner />}
    </div>
  );
}

export default CropDraftLists;
