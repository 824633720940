import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

function SelectSubState({ selectedSubState, setSelectedSubState, isDisabled, className }) {
  const subStates = [
    { label: 'pre-filtering', value: 'PRE-FILTERING' },
    { label: 'cleaning', value: 'CLEANING' },
    { label: 'cleaning qc', value: 'CLEANING_QC' },
    { label: 'identification', value: 'IDENTIFICATION' },
    { label: 'identification qc', value: 'IDENTIFICATION_QC' },
    { label: 're-process', value: 'REPROCESS' }
  ];

  return (
    <Select
      value={selectedSubState}
      onChange={setSelectedSubState}
      isDisabled={isDisabled}
      className={className}
      options={subStates}
      placeholder="Select Sub State"
      isSearchable
      isClearable
    />
  );
}

SelectSubState.propTypes = {
  selectedSubState: PropTypes.object,
  setSelectedSubState: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
};

SelectSubState.defaultProps = {
  selectedSubState: null,
  isDisabled: false,
  className: ''
};

export default SelectSubState;
